import React from 'react';

export default function TagChip(props) {

    const tag = props.tags.find(tag => {
        return tag.id === props.tag;
    });

    if (tag) {
        return (
            <div className="tag-chip">
                <span>{tag.name}</span>
            </div>
        )
    }
    return null
}
