import React from 'react';
import TagChip from '../TagChip'
import { PartnerModalContext } from '../PartnerModal/partnermodalcontext';

export default function PartnerCard(props) {

    const { partner, tags } = props

    const Modal = React.useContext(PartnerModalContext)

    const openModal = (e) => {
        Modal.open(e, partner)
    }

    const tagChips = partner.tags.map((item, index) => {
        return (
            <TagChip
                key={index}
                tag={item}
                tags={tags}
            />
        )
    })

    return (
        <button className="partner-card" onClick={openModal}>
            <div className="card-wrapper">
                <div className="image-frame">
                    <div className="image-wrapper">
                        <img src={partner.logo_url} alt="partner logo"/>
                    </div>
                </div>
                <div className="info-frame">
                    <div className="name">{partner.name}</div>
                    <div className="ia--tag-array">
                        {tagChips}
                    </div>
                    <span className="view-details">View Details</span>
                </div>
            </div>
        </button>
    )
}
