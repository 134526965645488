import React, { useEffect, useState } from 'react';
import PartnerCard from './components/PartnerCard';
import PartnerModalContextWrapper from './components/PartnerModal';
import FilterChip from './components/FilterChip';
import Search from './components/Search';

export default function App() {

    const [tags, setTags] = useState([])
    const [partners, setPartners] = useState([])

    useEffect((tags, partners) => {
        fetch('/partners/alliance-partners/fetch-alliance-partners/')
            .then(response => response.json())
            .then(json =>  {
                setTags(json.tags)
                setPartners(json.partners)
            })
    }, [])

    const [activeFilters, setActiveFilters] = useState([])

    // only get param filters once: on mount && tags have been set
    const didMount = React.useRef(false);

    useEffect(() => {
        if (tags.length && !didMount.current) {
            didMount.current = true;
            setActiveFilters(getParamFilters())
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tags])

    function getParamFilters() {
        const params = new URLSearchParams(window.location.search)
        const filters = params.get('filters')
        let filtersArray = []
        if (filters) {
            filtersArray = filters.split(',')
        }
        const result = filtersArray.filter((x) => {
            let found = false;
            if (x !== '') {
                tags.map((tag) => {
                    if (tag.slug === x) {
                        found = true
                    }
                    return null
                })
            }
            return found
        })
        return result
    }

    // pass to filter chips for updating active filters
    function updateFilters(tag, active) {
        if (active) {
            setActiveFilters([...activeFilters, tag])
        } else {
            setActiveFilters(activeFilters.filter(value => value !== tag))
        }
    }

    // for display
    const [activePartners, setActivePartners] = useState([])
    // for search
    const [activeTagPartners, setActiveTagPartners] = useState([])

    useEffect(() => {
        let results = []
        if (activeFilters.length) {
            partners.map((partner) => {
                let found = false
                partner.tags.map((item) => {
                    const tag = tags.find(tag => tag.id === item)
                    if (tag && activeFilters.includes(tag.slug)) {
                        found = true
                    }
                    return null
                })
                if (found) {
                    results.push(partner)
                }
                return null
            })
        } else {
            results = [...partners]
        }
        setActiveTagPartners((results))
        if (searchValue) {
            performSearch(searchValue, results)
        } else {
            setActivePartners((results))
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeFilters])

    const [searchValue, setSearchValue] = useState('')

    function performSearch(term, partners=activeTagPartners) {
        let results = []
        partners.map((partner) => {
            let found = false
            if (partner.name.search(new RegExp(term, "i")) !== -1) {
                found = true
            }
            if (!found && partner.about.search(new RegExp(term, "i")) !== -1) {
                found = true
            }
            if (!found && partner.partnership_description.search(new RegExp(term, "i")) !== -1) {
                found = true
            }
            if (!found) {
                partner.resources.map((resource) => {
                    if (!found && resource.title.search(new RegExp(term, "i")) !== -1) {
                        found = true
                    }
                })
            }
            if (found) {
                results.push(partner)
            }
            return null
        })
        setActivePartners((results))
    }

    const filterChips = tags.map((item) => {
        return (
            <FilterChip
                key={item.id}
                tag={item}
                activeFilters={activeFilters}
                updateFilters={updateFilters}
            />
        )
    })

    const partnerCards = activePartners.map((item, index) => {
        return (
            <PartnerCard
                key={index}
                partner={item}
                tags={tags}
            />
        )
    })

    return (
        <PartnerModalContextWrapper tags={tags}>
            <div className="filters">
                <div className="row">
                    <div className="small-12 columns">
                        <span className="filter-by">Filter by:</span>
                    </div>
                </div>
                <div className="row">
                    <div className="small-12 columns">
                        <div className="filter-search-wrapper">
                            <div id="filterWrapper" className="filter-wrapper">
                                <div className="filter-container left">
                                    <ul className="options">
                                        {filterChips}
                                    </ul>
                                </div>
                            </div>
                            <Search
                                search={performSearch}
                                searchValue={searchValue}
                                setSearchValue={setSearchValue}
                            />
                        </div>
                    </div>
                </div>
            </div>
            <div className="partner-cards">
                <div className="row">
                    <div className="small-12 columns">
                        <div className={`text-center${activePartners.length ? " hide" : ""}`} >
                            <p>
                                No results match filters
                            </p>
                        </div>
                        <div className="cards-grid">
                            {partnerCards}
                        </div>
                    </div>
                </div>
            </div>
        </PartnerModalContextWrapper>
    )
}
