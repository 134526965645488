import React, { useEffect, useState } from 'react';

export default function Search(props) {

    // const {  } = props

    const [label, setLabel] = useState('Search')
    // for desktop
    const [expanded, setExpanded] = useState(false)
    // for mobile
    const [shown, setShown] = useState(false)
    const filterWrapper = document.getElementById('filterWrapper');
    const desktopSearch = document.getElementById('desktopSearch');
    const mobileSearch = document.getElementById('mobileSearch');

    // for desktop
    function handleClick(event) {
        if (!expanded) {
            expandFocus(event)
        } else {
            collapseHide(event)
        }
    }

    function expandFocus(event) {
        setShown(true)
        expandDesktop(event)
        desktopSearch.focus()
    }

    function expandDesktop(event) {
        setLabel('Cancel')
        filterWrapper.classList.toggle('collapsed')
        setExpanded(true)
    }

    // for mobile
    function showMobile(event) {
        setShown(true)
        expandDesktop()
    }

    function collapseHide(event) {
        setLabel('Search')
        filterWrapper.classList.toggle('collapsed')
        setShown(false)
        setExpanded(false)
        props.setSearchValue('')
        props.search('')
    }

    useEffect(() => {
        const mobileSearch = document.getElementById('mobileSearch');
        if (shown) {
            mobileSearch.focus()
        }
    }, [shown])

    function searchClear(event) {
        props.setSearchValue('')
        mobileSearch.focus()
    }

    const onChange = (e) => props.setSearchValue(e.target.value)

    function handleSearch(event) {
        event.stopPropagation()
        event.preventDefault()
        props.search(props.searchValue)
    }

    return (
        <>
            <div className="desktop-search hide-for-small-down">
                <form className={`search right${expanded ? " expanded" : ""}`} onSubmit={handleSearch}>
                    <a className="open-search" onClick={handleClick}>{label}</a>
                    <input id="desktopSearch" value={props.searchValue} onChange={onChange} type="search" autoComplete="false" />
                    <div className="search-icon-wrapper">
                        <a className="search-icon" onClick={expanded ? handleSearch : expandFocus}>
                            <svg enableBackground="new 0 0 20 20" viewBox="-1 -1 22 22"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" stroke="#9b9b9b" strokeWidth="2">
                                    <circle cx="8.4" cy="8.4" r="7.4"></circle>
                                    <path d="m14.3 14.3 4.2 4.2" strokeLinecap="square"></path>
                                </g>
                            </svg>
                        </a>
                    </div>
                </form>
            </div>
            <div className="mobile-search show-for-small-down">
                <form className="search right" onSubmit={handleSearch}>
                    <div className="search-icon-wrapper">
                        <a className="search-icon" onClick={shown ? handleSearch : showMobile}>
                            <svg enableBackground="new 0 0 20 20" viewBox="-1 -1 22 22"
                                 xmlns="http://www.w3.org/2000/svg">
                                <g fill="none" stroke="#9b9b9b" strokeWidth="2">
                                    <circle cx="8.4" cy="8.4" r="7.4"></circle>
                                    <path d="m14.3 14.3 4.2 4.2" strokeLinecap="square"></path>
                                </g>
                            </svg>
                        </a>
                    </div>
                </form>
                <div className={`small-12 columns mobile-searchbar${shown ? " show" : ""}`}>
                    <form onSubmit={handleSearch}>
                        <input id="mobileSearch" value={props.searchValue} onChange={onChange} type="search" autoComplete="false" />
                            <div className="search-clear" onClick={searchClear}></div>
                    </form>
                    <div className="cancel" onClick={collapseHide}>Cancel</div>
                </div>
            </div>
        </>
    )
}
