import React, { useEffect, useState } from 'react';

export default function FilterChip(props) {

    const { tag, activeFilters, updateFilters } = props

    const [active, setActive] = useState(false)

    useEffect(() => {
        setActive(activeFilters.includes(tag.slug))
    }, [activeFilters, tag.slug])

    function handleClick(event) {
        setActive(!active)
    }

    // don't update filters on mount, only when updating active
    const didMount = React.useRef(false);

    useEffect(() => {
        if (didMount.current) {
            updateFilters(tag.slug, active)
        } else {
            didMount.current = true;
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [active])

    return (
        <div
            className={`filter-chip${active ? " active" : ""}`}
            onClick={handleClick}
        >
            <span>{tag.name}</span>
        </div>
    )
}
