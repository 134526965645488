import React from 'react';
import TagChip from '../TagChip';
import {PartnerModalContext} from './partnermodalcontext';

export default function PartnerModal(props) {
    const Modal = React.useContext(PartnerModalContext)

    const tagChips = props.partner.tags.map((item, index) => {
        return (
            <TagChip
                key={index}
                tag={item}
                tags={props.tags}
            />
        )
    })

    const tagResources = props.partner.resources.map((r, idx) => {
        return (
            // eslint-disable-next-line react/jsx-no-target-blank
            <a className="partner-resource" href={r.url} target='_blank' key={idx} >{r.title}</a>
        )
    })

    return (
        <div className="partner-modal" onClick={Modal.close}>
            <div className="partner-modal-window-wrapper" onClick={e => e.stopPropagation()}>
                <button className="close-button" title="Close" onClick={Modal.close}>
                    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
                        <path d="M19 6.41L17.59 5L12 10.59L6.41 5L5 6.41L10.59 12L5 17.59L6.41 19L12 13.41L17.59 19L19 17.59L13.41 12L19 6.41Z" fill="#4E4E4E"/>
                    </svg>
                </button>
                <div className="partner-logo-container">
                    <img src={props.partner.logo_url} alt={props.partner.name + " logo"}/>
                </div>
                <div className="partner-text-container">
                    <div className="partner-description">
                        <h3>{props.partner.name}</h3>
                        <p>{props.partner.about}</p>
                    </div>
                    <div className="partner-description">
                        <h3>Our Partnership</h3>
                        <p>{props.partner.partnership_description}</p>
                    </div>

                    {props.partner.resources && props.partner.resources.length > 0 &&
                    <div className="partner-resources">
                        <h3>Additional Resources</h3>
                        {tagResources}
                    </div>}

                    {props.partner.tags && props.partner.tags.length > 0 &&
                    <div className="ia--tag-array">
                        {tagChips}
                    </div>}
                </div>
                <div className="partner-website-link">
                    {/* eslint-disable-next-line react/jsx-no-target-blank */}
                    <a href={props.partner.website_link} target="_blank" className="ia-primary-button"><span>Visit Partner Website</span></a>
                </div>
            </div>
        </div>
    )
}
