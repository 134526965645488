import React from 'react';
import PartnerModal from './partnermodal';
import {PartnerModalContext} from './partnermodalcontext';

export default function PartnerModalContextWrapper(props) {
    const [detailModalPartner, setDetailModalPartner] = React.useState(null)

    const context = {
        close: (e) => {
            e.preventDefault();
            e.stopPropagation();
            setDetailModalPartner(null)
            document.body.classList.remove("no-scroll")
        },

        open: (e, p) => {
            e.preventDefault()
            setDetailModalPartner(p)
            document.body.classList.add("no-scroll")
        }
    }

    return (
        <PartnerModalContext.Provider value={context}>
            {props.children}
            {detailModalPartner ? <PartnerModal partner={detailModalPartner} tags={props.tags} /> : <></>}
        </PartnerModalContext.Provider>
    )
}
